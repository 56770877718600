<template>
    <form novalidate=true @submit.prevent="enviarFormulario">
        <div class="row mb-3" v-if="!isModal">
            <div class="col-6">
                <h2 class="text-dark">
                    {{ titulo }}
                </h2>
            </div>
		</div>
        <div class="row">
            <div class="row col-md-12">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="rut-completo" class="form_label ">Rut</label>
                        <input class="form-control" :class="{'is-invalid': validaciones.rut.error}" @keyup="formateaRut()" @change="buscar()" type="text" v-model="form.rut" id="rut-completo" />
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Nombre</label>
                        <input class="form-control" type="text" v-model="form.name" :readonly="readonly" :class="{'is-invalid': validaciones.name.error}" required="required" maxlength="255" id="name">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="address" class="form_label ">Dirección</label>
                        <input class="form-control" type="text" v-model="form.address" :readonly="readonly" maxlength="255" id="address">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="phone" class="form_label ">Teléfono</label>
                        <input class="form-control" type="text" v-model="form.phone" :readonly="readonly" maxlength="20" id="phone">
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="status">Activo</label>
                    <div class="w-100">
                        <p-check class="p-default p-bigger" color="primary-o" v-model="form.status" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" v-if="!isModal">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Volver" @click="volver">
                    <font-awesome-icon icon="arrow-left" /> Volver
                </button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-success" title="Guardar" v-loading-btn="{loading: loading}" >
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Funciones, Validaciones } from '../../utils/funciones'

library.add([faArrowLeft, faSave, faPlus]);

export default {
    name: 'EdificiosForm',
    props: {
        save: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        save: function (value) {
            if(value) {
                this.enviarFormulario();
            }
        }
    },
    data: () => {
        return {
            titulo: 'Crear edificio',
            loading: false,
            estaEditando: false,
            form: {
                id: null,
                rut: null,
                name: null,
                address: null,
                phone: null,
                status: true
            },
            readonly: true,
            validaciones: {
                rut: {
                    error: false,
                    reglas: 'rut'
                },
                name: {
                    error: false,
                    reglas: 'not-empty'
                }
            }
        }
    },
    methods: {
        volver() {
            this.$router.push({path: '/edificios'});
        },
        formateaRut() {
            let self = this;
            if(self.form.rut && self.form.rut.length > 1) {
                self.form.rut = Funciones.formateaRut(self.form.rut); 
            }
        },
        buscar() {
            let self = this;
            if(Funciones.Valida_Rut(self.form.rut)) {
                self.validaciones.rut.error = false;
                self.axios.get(`buildings/find`, {
                    params: {rut: self.form.rut}
                })
                    .then(function (response) {
                        if(!response.error) {
                            if(response.data == null || response.data.length == 0 ) {
                                self.readonly = false;
                                self.form.id = null;
                                self.form.name = null;
                                self.form.address = null;
                                self.form.phone = null;
                            } else {
                                self.readonly = true;
                                self.form.id = response.data.id;
                                self.form.name = response.data.name;
                                self.form.address = response.data.address;
                                self.form.phone = response.data.phone;
                                self.$toastr.w(`El rut <b>${self.form.rut}</b> existe en el sistema`, "Advertencia");
                            }
                        } else {
                            self.$toastr.e(response.message, "Error");
                        }
                    }).catch(function (error) {
                        self.$toastr.e(error, "Exception");
                    });
            } else {
                self.validaciones.rut.error = true;
                self.$toastr.e("Debe ingresar un rut válido", "Error");
            }
        },
        enviarFormulario() {
            let self = this;

            self.loading = true;
            Validaciones.reset(self.validaciones);
            Validaciones.validar(self.form, self.validaciones);
            let tieneError = Validaciones.hasError(self.validaciones);

            if(!tieneError) {
                self.$emit('update:save', true);
                const data = {...self.form };
                data['rut_completo'] = self.form.rut;
                delete data.rut;

                let $promise = null;
                if(self.estaEditando) {
                    delete data.id;
                    $promise = self.axios.put(`buildings/${self.$route.params.id}`, data);
                } else {
                    $promise =  self.axios.post(`buildings`, data);
                }
                
                $promise
                .then(function(response) {
                    self.$emit('update:save', false);
                    if(response.error) {
                        self.loading = false;
                        self.$toastr.e(response.message, "Error al guardar edificio");
                    } else {
                        self.loading = false;
                        self.$toastr.s("Edificio guardado exitosamente", "Correcto");
                        self.$emit('entity', response.data);

                        if(!self.isModal) {
                            self.volver();
                        }
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    self.$emit('update:save', false);
                    self.$toastr.e(error, "Exception");
                });
            } else {
                self.loading = false;
                self.$toastr.e("El formulario tiene errores, verifique", "Error");
                self.$emit('update:save', false);
            }
        }
    },
    beforeMount: function(){
		let self = this;

        if(self.$route.params.id) {
            self.estaEditando = true;
            self.titulo = 'Editar edificio';

            self.axios.all([
                self.axios.get(`buildings/${self.$route.params.id}`)
            ])
            .then(self.axios.spread(
                (dataEdificio) => {
                    self.form.id = dataEdificio.data.id;
                    self.form.rut = dataEdificio.data.rut_completo;
                    self.form.name = dataEdificio.data.name;
                    self.form.address = dataEdificio.data.address;
                    self.form.phone = dataEdificio.data.phone;
                    self.form.status = dataEdificio.data.status;

                    self.readonly = false;
                }
            )).catch((err) => {
                self.$toastr.e(err, "Exception");
            });
        }
    }
}
</script>

<style>
</style>